<template>
  <div>
    <div class="row">
      <div class="col-md-12 text-right">
        <vb-create-system @reload="reload()" />
      </div>
    </div>
    <vb-edit-system
      v-if="editModal"
      :system-id="systemId"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #order="{ record }">
        {{ record.order }}
      </template>
      <template #name="{ record }">
        <img :src="record.icon" height="22" alt="" class="float-left mr-2">
        <span class="payment-name" style="text-transform: capitalize">
          {{ record.name.toLowerCase() }}
        </span>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #disabled="{ record }">
        <a-popconfirm
          v-if="!record['disabled']"
          title="Деактивировать платежную систему?"
          @confirm="updateColumn(record.id, record.name, 'disabled', 1)"
          @cancel="() => {}"
          ok-text="Да"
          cancel-text="Нет"
        >
          <a-tag
            color="#108ee9"
            class="cursor-pointer"
          >Доступен</a-tag>
        </a-popconfirm>
        <a-popconfirm
          v-else
          title="Активировать платежную систему?"
          @confirm="updateColumn(record.id, record.name, 'disabled', 0)"
          @cancel="() => {}"
          ok-text="Да"
          cancel-text="Нет"
        >
          <a-tag
            class="cursor-pointer"
          >Недоступен</a-tag>
        </a-popconfirm>
      </template>
      <template #hidden="{ record }">
        <a-popconfirm
          v-if="!record['hidden']"
          title="Скрыть платежную систему?"
          @confirm="updateColumn(record.id, record.name, 'hidden', 1)"
          @cancel="() => {}"
          ok-text="Да"
          cancel-text="Нет"
        >
          <a-tag
            color="#108ee9"
            class="cursor-pointer"
          >Отображается</a-tag>
        </a-popconfirm>
        <a-popconfirm
          v-else
          title="Отображать платежную систему?"
          @confirm="updateColumn(record.id, record.name, 'hidden', 0)"
          @cancel="() => {}"
          ok-text="Да"
          cancel-text="Нет"
        >
          <a-tag
            class="cursor-pointer"
          >Не отображается</a-tag>
        </a-popconfirm>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="showEditModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import { watch, computed, reactive, ref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import VbCreateSystem from "@/views/payment-system/create";
import VbEditSystem from "@/views/payment-system/edit";
import apiClient from "@/services/axios";
import { useStore } from "vuex";
import {message} from "ant-design-vue";

let
  dataSource = ref([]),
  imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  editModal = ref(false),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  total = ref(0),
  systemId = ref(0);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  searchInput = ref(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 20,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: '#',
      key: 'order',
      dataIndex: 'order',
    },
    {
      title: 'Название пл. системы',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      slots: {
        customRender: 'name',
      },
    },
    {
      title: 'Доступность',
      dataIndex: 'disabled',
      key: 'disabled',
      sorter: true,
      slots: {
        customRender: 'disabled',
      },
    },
    {
      title: 'Отображение',
      dataIndex: 'hidden',
      key: 'hidden',
      sorter: true,
      slots: {
        customRender: 'hidden',
      },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      slots: { customRender: 'updated' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  showEditModal = (id) => {
    systemId.value = id
    editModal.value = true;
  },
  hideEditModal = () => {
    setTimeout(() => {
      editModal.value = false
    }, 100)
  },
  formattedBalance = (balance) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance / 100)
    return formattedBalance.substring(0, formattedBalance.length - 4)
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateColumn = (id, name, column, value) => {
    let data = {};
    data['name'] = name;
    data[column] = value;
    apiClient.put(`/payment-system/update?id=${id}`, data).then(response => {
      if (response) {
        message.success('Пл. система успешно обновлена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/payment-system/index', { params }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'payment-systems',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped src="./index.css"></style>
